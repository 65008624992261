import React, { useMemo } from 'react';
import cn from 'classnames';
import { ModuleHeader } from '../../../components';
import { DiscoveryBrandSponsorOrganisation } from '../../../../../../../store/features/discovery';
import { Anchor } from '../../../../../../shared/Anchor';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';
import {
  SponsorsModuleType,
  SponsorsModuleLayoutTypes,
  SponsorsModuleRotateTypes,
} from '../types';
import { PropsWithTestId } from '../../../../../../../types';
import { CallToAction } from '../../../../CallToActionGroup';
import Autoplay from 'embla-carousel-autoplay';
import AutoScroll from 'embla-carousel-auto-scroll';
import useEmblaCarousel from 'embla-carousel-react';
import { useRootSelector } from '../../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../../store/features/pageConfig';
import { generateDomainUrl } from '../../../../../../../helpers/generateDomainUrl';
import s from './index.module.scss';

export interface ScrollingLogosDisplayStyleProps extends SponsorsModuleType {}

const rotateSliderSettings = {
  [SponsorsModuleRotateTypes.SINGLE]: {
    loop: true,
    slidesToScroll: 1,
    speed: 2,
  },
  [SponsorsModuleRotateTypes.SMOOTH]: {
    loop: true,
    speed: 2,
  },
  [SponsorsModuleRotateTypes.PAGE]: {
    loop: true,
    slidesToScroll: 5,
    speed: 2,
  },
};

function addFakeElements(
  array: DiscoveryBrandSponsorOrganisation[],
  desiredLength: number,
) {
  if (array.length < desiredLength) {
    const fakeElements = Array(desiredLength - array.length).fill({ id: '' });
    return array.concat(fakeElements);
  } else {
    return array;
  }
}

export function ScrollingLogosDisplayStyle(
  props: PropsWithTestId<ScrollingLogosDisplayStyleProps>,
) {
  const {
    rotateType,
    layoutType,
    title,
    sponsorsFromDiscovery = [],
    testId,
    link,
  } = props;

  const sliderSettings = rotateSliderSettings[rotateType];

  const autoplaySettings = Autoplay({
    playOnInit: true,
    stopOnMouseEnter: true,
    stopOnInteraction: false,
    delay: 3500,
  });

  const autoscrollSettings = AutoScroll({
    playOnInit: true,
    stopOnMouseEnter: true,
    stopOnInteraction: false,
    stopOnFocusIn: false,
  });

  const platType =
    rotateType === SponsorsModuleRotateTypes.SMOOTH
      ? autoscrollSettings
      : autoplaySettings;
  const [emblaRef] = useEmblaCarousel(sliderSettings, [platType]);
  const { tenantsConfig: domains } = useRootSelector(selectPageConfig);

  const organizationsWithLogo = useMemo(() => {
    return sponsorsFromDiscovery.reduce((acc, spexBlock) => {
      const spexBlocksWithLogo = spexBlock.spexs.filter(
        (organisation) => organisation.photo,
      );

      const sponsorsWithLink = spexBlocksWithLogo.map((sponsor) => {
        const path = `${spexBlock.sitePath}/sponsors/${sponsor.path}`;
        const url = generateDomainUrl({
          domains,
          domain: spexBlock.domain,
          tenant: spexBlock.tenant,
          path,
        });

        return {
          ...sponsor,
          path: url,
        };
      });

      return [...acc, ...sponsorsWithLink];
    }, [] as DiscoveryBrandSponsorOrganisation[]);
  }, [sponsorsFromDiscovery, domains]);

  const sponsorsWithFakeElements = addFakeElements(organizationsWithLogo, 6);

  const containerClassName = cn('container', {
    'container-fluid': layoutType === SponsorsModuleLayoutTypes.FULLSCREEN,
  });

  return (
    <div data-testid={testId}>
      {title && (
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <ModuleHeader title={title} lineAfterHeading />
            </div>
          </div>
        </div>
      )}
      <div className={containerClassName}>
        <div className={s.viewport} ref={emblaRef}>
          <div className={s.sliderContainer}>
            {sponsorsWithFakeElements.map((organisation, index: number) => (
              <div className={s.embla__slide} key={index}>
                <Anchor to={organisation.path}>
                  <ImageTag
                    lazy={false}
                    className={s.holder}
                    src={organisation.photo}
                    alt=""
                  />
                </Anchor>
              </div>
            ))}
          </div>
        </div>
      </div>
      {link?.type && (
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <CallToAction
                link={link}
                variant="contained"
                align="center"
                data-testid="hub-module-link"
                className={s.cta}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ScrollingLogosDisplayStyle.defaultProps = {
  testId: 'hub-sponsors-module-scrolling-logos',
};
